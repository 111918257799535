import { useCallback } from 'react';

import { usePintoUploadEbookBundleMutation } from '../core/graphql/operation-hooks';
import { UploadEbookBundleInput } from '../core/graphql/types';

const useMutationPintoUploadEbookBundle = () => {
  const [mutatePintoUploadEbookBundle, { loading: isLoadingMutatePintoUploadEbookBundle }] = usePintoUploadEbookBundleMutation();

  const onMutatePintoUploadEbookBundle = useCallback(
    (input: UploadEbookBundleInput) => {
      const variables = { input };
      return mutatePintoUploadEbookBundle({ variables });
    },
    [mutatePintoUploadEbookBundle],
  );

  return {
    onMutatePintoUploadEbookBundle,
    isLoadingMutatePintoUploadEbookBundle,
  };
};

export default useMutationPintoUploadEbookBundle;
