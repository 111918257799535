import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { MdDelete, MdCreate, MdVisibilityOff, MdVisibility } from 'react-icons/md';
import { Link, useHistory, useLocation } from 'react-router-dom';

import SchedulePublishModal from '../../app/ebooks/modals/SchedulePublishModal';
import AboutDetailPresenter, { ICategoryItem } from '../../app/ebooks/presenters/AboutDetailPresenter';
import CoverAndBannerDetailPresenter from '../../app/ebooks/presenters/CoverAndBannerDetailPresenter';
import DetailPresenter from '../../app/ebooks/presenters/DetailPresenter';
import EbookFilesPresenter from '../../app/ebooks/presenters/EbookFilesPresenter';
import PriceDetailPresenter from '../../app/ebooks/presenters/PriceDetailPresenter';
import SalesReportDetailPresenter from '../../app/ebooks/presenters/SalesReportDetailPresenter';
import StatusDetailPresenter from '../../app/ebooks/presenters/StatusDetailPresenter';
import ConfirmModal from '../../app/modals/ConfirmModal';
import images from '../../assets/images';
import Alert from '../../components/Alert';
import Breadcrumbs from '../../components/Breadcrumbs';
import Button from '../../components/Button';
import LoadingModal from '../../components/modal/LoadingModal';
import ToastComponent, { IToastType } from '../../components/Toast/ToastComponent';
import { useCurrentUserLazyQuery } from '../../core/graphql/operation-hooks';
import { ContentType, CorrectionEbookFile, EbookDetailFragment, EbookFile, EbookFileType, EbookStatus, ReadingType, SubmitReviewEbookInput } from '../../core/graphql/types';
import useMutationPintoHideEbook from '../../hooks/useMutationPintoHideEbook';
import useMutationPintoRemoveEbook from '../../hooks/useMutationPintoRemoveEbook';
import useMutationPintoUnHideEbook from '../../hooks/useMutationPintoUnHideEbook';
import useMutationSubmitReviewDraftEbook from '../../hooks/useMutationSubmitReviewDraftEbook';
import useQueryPintoEbook from '../../hooks/useQueryPintoEbook';
import { endOf, minusDateEbook } from '../../utils/dayjs';

import '../../styles/synopsis.css';

function EbookDetailPage() {
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const ebookId = searchParams.get('id');
  const { onFetchPintoEbook, isLoadingPintoEbook, pintoEbookData } = useQueryPintoEbook();
  const { onMutatePintoHideEbook } = useMutationPintoHideEbook();
  const { onMutatePintoUnHideEbook } = useMutationPintoUnHideEbook();
  const { onMutatePintoRemoveEbook } = useMutationPintoRemoveEbook();
  const { submitReviewDraftEbook } = useMutationSubmitReviewDraftEbook();
  const [queryCurrentUser, { data: user }] = useCurrentUserLazyQuery();

  const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false);
  const [showConfirmRemoveEbookModal, setShowConfirmRemoveEbookModal] = useState<boolean>(false);
  const [showConfirmHideEbookModal, setShowConfirmHiedEbookModal] = useState<boolean>(false);

  // schedule publish
  const [showSchedulePublishModal, setSchedulePublishModal] = useState<boolean>(false);

  /**
   * NOTES: backend use logic check endedDate is start of day (endDate+1 00.00.00),
   * so we should convert to end of day (endDate 23.59.59) for date picker can render correctly.
   */
  const getDiscountEndDateFromQuery = useCallback((endDate?: string | null) => {
    if (!endDate) return undefined;

    const dateData = new Date(endDate);

    if (dateData.getHours() === 23 && dateData.getMinutes() === 59 && dateData.getSeconds() === 59) {
      const _endDate = endOf(endDate, 'day');
      return minusDateEbook(0, undefined, _endDate);
    }

    return dateData;
  }, []);

  useEffect(() => {
    queryCurrentUser();
  }, [queryCurrentUser]);

  useEffect(() => {
    if (ebookId) {
      onFetchPintoEbook(ebookId);
    }
  }, [ebookId, onFetchPintoEbook]);

  const ebookPdfFiles = useMemo(() => {
    const pdfCorrectionFile = pintoEbookData?.correction?.correctionEbookFiles.find((file) => file.type === EbookFileType.Pdf);
    if (pdfCorrectionFile) {
      return { ...pdfCorrectionFile, sizeInKB: undefined };
    }
    return pintoEbookData?.ebookFiles.find((file) => file.type === EbookFileType.Pdf);
  }, [pintoEbookData?.correction?.correctionEbookFiles, pintoEbookData?.ebookFiles]);

  const ebookEpubFiles = useMemo(() => {
    const epubCorrectionFile = pintoEbookData?.correction?.correctionEbookFiles.find((file) => file.type === EbookFileType.Epub);
    if (epubCorrectionFile) {
      return { ...epubCorrectionFile, sizeInKB: undefined };
    }
    return pintoEbookData?.ebookFiles.find((file) => file.type === EbookFileType.Epub);
  }, [pintoEbookData?.correction?.correctionEbookFiles, pintoEbookData?.ebookFiles]);

  const getFormattedEbookFileName = useCallback((fileName?: string | null, fileSize?: number | null) => {
    if (!fileName) return undefined;
    if (!fileSize) return fileName;
    if (fileSize < 1024) return `${fileName} (${(fileSize || 0).toFixed(2)} KB)`;

    return `${fileName} (${((fileSize || 0) / 1024).toFixed(2)} MB)`;
  }, []);

  const getParentCategories = useCallback((categoryItems: ICategoryItem[], categories?: EbookDetailFragment['category']) => {
    if (!categories?._id) {
      return;
    }
    categoryItems.push({ id: categories?._id || '', name: categories?.name || '' });
    if (categories?.parent) {
      getParentCategories(categoryItems, categories.parent);
    }
  }, []);

  const getCategoryItems = useCallback(
    (categories?: EbookDetailFragment['category']) => {
      const categoryItems: ICategoryItem[] = [];

      getParentCategories(categoryItems, categories);
      return categoryItems.reverse();
    },
    [getParentCategories],
  );

  const onHideEbook = useCallback(async () => {
    if (ebookId) {
      setShowLoadingModal(true);
      const isSuccess = await onMutatePintoHideEbook(ebookId);
      setShowLoadingModal(false);
      if (isSuccess) {
        ToastComponent({ label: 'Hide ebook successfully ', type: IToastType.SUCCESS });
      }
    }
  }, [ebookId, onMutatePintoHideEbook]);

  const onUnHideEbook = useCallback(async () => {
    if (ebookId) {
      setShowLoadingModal(true);
      const isSuccess = await onMutatePintoUnHideEbook(ebookId);
      setShowLoadingModal(false);
      if (isSuccess) {
        ToastComponent({ label: 'Publish ebook successfully ', type: IToastType.SUCCESS });
      }
    }
  }, [ebookId, onMutatePintoUnHideEbook]);

  const onRemoveEbook = useCallback(async () => {
    if (ebookId && user?.currentUser?._id) {
      setShowLoadingModal(true);
      const isSuccess = await onMutatePintoRemoveEbook(ebookId, user?.currentUser?._id);
      setShowLoadingModal(false);

      if (isSuccess) {
        ToastComponent({ label: 'Remove ebook successfully ', type: IToastType.SUCCESS });
        history.push('/ebooks');
      }
    }
  }, [ebookId, history, onMutatePintoRemoveEbook, user?.currentUser?._id]);

  const onConfirmHideUnHideEbook = useCallback(() => {
    if (pintoEbookData?.status === EbookStatus.Public) {
      onHideEbook();
    } else if (pintoEbookData?.status === EbookStatus.Hidden) {
      onUnHideEbook();
    }
    setShowConfirmHiedEbookModal(false);
  }, [onHideEbook, onUnHideEbook, pintoEbookData?.status]);

  const getSubmitFilesInput = useCallback((ebookFiles?: Omit<EbookFile, 'pagesCount'>[], correctionEbookFiles?: CorrectionEbookFile[]) => {
    if (correctionEbookFiles) {
      return correctionEbookFiles.map((file) => ({
        mainFileUrl: file.mainFileUrl || '',
        mainFilename: file.mainFilename || '',
        sampleFileUrl: file.sampleFileUrl || '',
        sampleFilename: file.sampleFilename || '',
        type: file.type,
      }));
    } else if (ebookFiles) {
      return ebookFiles.map((file) => ({
        mainFileUrl: file.mainFileUrl || '',
        mainFilename: file.mainFilename || '',
        sampleFileUrl: file.sampleFileUrl || '',
        sampleFilename: file.sampleFilename || '',
        type: file.type,
      }));
    }
    return [];
  }, []);

  const getSubmitReviewInput = useCallback(() => {
    let input: SubmitReviewEbookInput | undefined = undefined;
    if (pintoEbookData) {
      input = {
        coverImageUrl: pintoEbookData.coverImageUrl,
        bannerImageUrl: pintoEbookData.bannerImageUrl,
        title: pintoEbookData.title || '',
        description: pintoEbookData.description || '',
        categoryId: pintoEbookData.correction?.category?._id || pintoEbookData.category?._id || '1',
        writingType: pintoEbookData.writingType,
        authorNames: pintoEbookData.authorNames as SubmitReviewEbookInput['authorNames'],
        translatorNames: pintoEbookData.translatorNames as SubmitReviewEbookInput['translatorNames'],
        synopsis: pintoEbookData.synopsis,
        originalLanguageCode: pintoEbookData.originalLanguage.code,
        languageCode: pintoEbookData.language.code,
        isbn: pintoEbookData.isbn,
        readingType: pintoEbookData.readingType,
        coverPriceThb: pintoEbookData.coverPriceThb,
        originalPriceThb: pintoEbookData.originalPriceThb || 0,
        productSetId: pintoEbookData.productSet?._id,
        tagNames: pintoEbookData.tags.map((tag) => tag.name),
        ebookFiles: getSubmitFilesInput(pintoEbookData?.ebookFiles, pintoEbookData.correction?.correctionEbookFiles),
        publishedAt: pintoEbookData.publishedAt,
      };

      if (pintoEbookData.discount) {
        input.discount = {
          ratio: pintoEbookData.discount?.ratio,
          thb: pintoEbookData.discount?.thb,
          startedAt: pintoEbookData.discount?.startedAt,
          endedAt: pintoEbookData.discount?.endedAt,
        };
      }

      if (pintoEbookData.productSetVol) {
        input.productSetVol = pintoEbookData.productSetVol;
      }
    }
    return input;
  }, [getSubmitFilesInput, pintoEbookData]);

  const onSubmitPublishEbook = useCallback(
    async (publishedAt?: Date | null) => {
      if (pintoEbookData?._id) {
        const input = getSubmitReviewInput();

        if (!input) return;
        input.publishedAt = publishedAt;
        try {
          setShowLoadingModal(true);
          await submitReviewDraftEbook(pintoEbookData?._id, input);
        } catch (error) {
          console.log(error);
        } finally {
          setShowLoadingModal(false);
        }
      }
    },
    [getSubmitReviewInput, pintoEbookData?._id, submitReviewDraftEbook],
  );

  const onSubmitCancelScheduledPublishEbook = useCallback(async () => {
    onSubmitPublishEbook(null);
  }, [onSubmitPublishEbook]);

  return (
    <div className="space-y-12">
      {isLoadingPintoEbook && !pintoEbookData ? (
        <div className="w-full btn btn-outline btn-disabled bg-systemGrays04LabelQuaternary border-none loading h-[100px]" />
      ) : (
        <>
          <div className="sticky top-0 z-50 px-5 bg-componentsBgGrouped02">
            {/* section: breadcrumbs */}
            <Breadcrumbs items={[{ name: 'Ebooks', url: '/ebooks' }, { name: pintoEbookData?.title || 'Ebook Detail' }]} />
            {/* section: title */}
            <div className="flex flex-col justify-between pt-5 md:flex-row">
              <div className="w-[70%] h-full">
                <h1 className="font-dbh text-[28px] break-words">{pintoEbookData?.title || 'Ebook Detail'}</h1>
              </div>

              <div className="flex items-center space-x-12">
                <Link to="/ebooks">
                  <Button type="outline" color="gray" className="space-x-4">
                    Back
                  </Button>
                </Link>
                {pintoEbookData?.status !== EbookStatus.Removed && (
                  <>
                    <Button onClick={() => setShowConfirmRemoveEbookModal(true)} type="outline" color="red" className="space-x-4">
                      <MdDelete size={24} />
                      <div>Remove</div>
                    </Button>

                    {![EbookStatus.Public, EbookStatus.Scheduled].includes(pintoEbookData?.status as EbookStatus) && (
                      <Link to={`/ebooks/edit?id=${pintoEbookData?._id}`}>
                        <Button type="outline" color="black" className="space-x-4">
                          <MdCreate size={24} />
                          <div>Edit</div>
                        </Button>
                      </Link>
                    )}
                  </>
                )}

                {(pintoEbookData?.status === EbookStatus.Public || pintoEbookData?.status === EbookStatus.Hidden) && (
                  <div className="dropdown dropdown-bottom dropdown-end">
                    <label tabIndex={0} className="m-1">
                      <Button type="outline" color="black" className="space-x-4 w-[150px]" onClick={() => setShowConfirmHiedEbookModal(true)}>
                        <input type="checkbox" className="toggle toggle-accent" checked={pintoEbookData?.status === EbookStatus.Public} />
                        <div
                          className={classNames({
                            'text-colorsBrandWarmBlack02LabelSecondary': pintoEbookData?.status === EbookStatus.Hidden,
                            ' text-colorsPintoBlueprint01Primary': pintoEbookData?.status === EbookStatus.Public,
                          })}
                        >
                          {pintoEbookData?.status.toLocaleUpperCase()}
                        </div>
                      </Button>
                    </label>
                    {showConfirmHideEbookModal && (
                      <div tabIndex={0} className="dropdown-content card card-compact p-2 w-[350px] shadow bg-base-100">
                        <div className="card-body space-y-12 !p-24">
                          <h3 className="card-title font-dbh !mb-0 flex items-center">
                            <span className="mr-4">{pintoEbookData?.status === EbookStatus.Public ? <MdVisibilityOff size={24} /> : <MdVisibility size={24} />}</span>
                            {`${pintoEbookData?.status === EbookStatus.Public ? 'Unpublish' : 'Publish'} Confirmation`}
                          </h3>
                          <p className="font-tt">
                            {`Are you confirm to ${pintoEbookData?.status === EbookStatus.Public ? 'unpublish' : 'publish'}: `}{' '}
                            <span className="font-medium">{pintoEbookData?.title}</span>?
                          </p>
                          <div className="flex justify-center w-full space-x-6">
                            <button className="btn btn-outline" onClick={() => setShowConfirmHiedEbookModal(false)}>
                              Cancel
                            </button>
                            <button className="btn btn-error" onClick={onConfirmHideUnHideEbook}>
                              Confirm
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {pintoEbookData?.status === EbookStatus.Draft && pintoEbookData?.publishedAt && (
                  <Button type="fill" color="black" className="space-x-4" onClick={onSubmitCancelScheduledPublishEbook}>
                    <IoMdCloseCircleOutline size={24} />
                    <div>ยกเลิกตั้งเวลา</div>
                  </Button>
                )}
              </div>
            </div>

            {pintoEbookData?.correction?.rejectNotes && (pintoEbookData?.correction?.rejectNotes?.length || 0) > 0 && (
              <div className="pt-16">
                <Alert type="error" message="อีบุ๊กไม่ผ่านการอนุมัติเนื่องจาก" description={pintoEbookData?.correction?.rejectNotes} showIcon />
              </div>
            )}

            {/* section: main */}
            <div className="mb-0 divider opacity-10"></div>
          </div>

          {/* cover */}
          <CoverAndBannerDetailPresenter
            coverFileUrl={pintoEbookData?.coverImageUrl || images.pinto.default.ebookCover}
            bannerFileUrl={pintoEbookData?.bannerImageUrl || images.pinto.default.bannerCover}
          />
          {/* price */}
          <PriceDetailPresenter
            isFree={pintoEbookData?.isFree || false}
            webPrice={pintoEbookData?.originalPriceThb || 0}
            webDiscountRatio={pintoEbookData?.discount?.ratio || undefined}
            webDiscountPrice={pintoEbookData?.discount?.thb || undefined}
            googlePriceTier={pintoEbookData?.googleOriginalPriceTier || undefined}
            applePriceTier={pintoEbookData?.appleOriginalPriceTier || undefined}
            discountGooglePriceTier={pintoEbookData?.discount?.googlePriceTier || undefined}
            discountApplePriceTier={pintoEbookData?.discount?.applePriceTier || undefined}
            discountStartDate={pintoEbookData?.discount?.startedAt ? new Date(pintoEbookData?.discount?.startedAt) : undefined}
            discountEndDate={pintoEbookData?.discount?.endedAt ? getDiscountEndDateFromQuery(pintoEbookData?.discount?.endedAt) : undefined}
          />
          {/* detail */}
          <DetailPresenter
            ebookId={pintoEbookData?._id || ''}
            description={pintoEbookData?.description || ''}
            ebookStatus={pintoEbookData?.status}
            reviewStatus={pintoEbookData?.correctionEbookStatus || undefined}
            authorNames={(pintoEbookData?.authorNames || []) as []}
            translatorNames={(pintoEbookData?.translatorNames || []) as []}
            contentRawState={pintoEbookData?.synopsis}
            user={{
              userName: pintoEbookData?.user?.username || '',
              name: pintoEbookData?.user?.displayName || '',
            }}
            publishedAt={pintoEbookData?.publishedAt}
          />

          {/* about */}
          <AboutDetailPresenter
            productSetId={pintoEbookData?.productSet?._id}
            productSetName={pintoEbookData?.productSet?.title || undefined}
            subscriptionTitle={pintoEbookData?.subscriptionTitle?.title}
            translatedLanguage={pintoEbookData?.language.name}
            originalLanguage={pintoEbookData?.originalLanguage.name}
            tags={pintoEbookData?.tags.map((tag) => ({ id: tag._id, name: tag.name }))}
            categories={getCategoryItems((pintoEbookData?.correction?.category as EbookDetailFragment['category']) || pintoEbookData?.category) || []}
            writingType={
              (pintoEbookData?.correction?.category?.contentType || pintoEbookData?.category?.contentType) === ContentType.Comic ? pintoEbookData?.writingType : undefined
            }
          />

          <div className="flex flex-col space-y-12 md:flex-row md:justify-between md:space-y-0 md:space-x-24">
            {/* status */}
            <StatusDetailPresenter
              createdAt={pintoEbookData?.createdAt}
              createdBy={pintoEbookData?.summaryEbookLog?.created?.actor.displayName || ''}
              publishedAt={pintoEbookData?.publishedAt}
              updatedAt={pintoEbookData?.updatedAt}
              updatedBy={pintoEbookData?.summaryEbookLog?.latestUpdated?.actor.displayName || ''}
            />
            {/* sale report */}
            <SalesReportDetailPresenter totalSales={pintoEbookData?.totalSales || 0} purchaseCount={pintoEbookData?.purchasesCount || 0} />
          </div>

          {/* files */}
          <EbookFilesPresenter
            readOnly
            // pdf
            mainPdfFileName={getFormattedEbookFileName(ebookPdfFiles?.mainFilename, ebookPdfFiles?.sizeInKB)}
            mainPdfFileUrl={ebookPdfFiles?.mainFileUrl || undefined}
            samplePdfFileName={getFormattedEbookFileName(ebookPdfFiles?.sampleFilename, null)}
            samplePdfFileUrl={pintoEbookData?.ebookFiles[0]?.sampleFileUrl || undefined}
            selectedMainPdfFile={undefined}
            selectedSamplePdfFile={undefined}
            onSelectMainPdfFile={() => undefined}
            onSelectSamplePdfFile={() => undefined}
            // epub
            mainEpubFileName={getFormattedEbookFileName(ebookEpubFiles?.mainFilename, ebookEpubFiles?.sizeInKB)}
            mainEpubFileUrl={ebookEpubFiles?.mainFileUrl || undefined}
            sampleEpubFileName={getFormattedEbookFileName(ebookEpubFiles?.sampleFilename, null)}
            sampleEpubFileUrl={ebookEpubFiles?.sampleFileUrl || undefined}
            selectedMainEpubFile={undefined}
            selectedSampleEpubFile={undefined}
            onSelectMainEpubFile={() => undefined}
            onSelectSampleEpubFile={() => undefined}
            readingType={pintoEbookData?.readingType || ReadingType.LeftToRight}
          />
        </>
      )}
      {showLoadingModal && <LoadingModal />}

      {showConfirmRemoveEbookModal && (
        <ConfirmModal
          onClose={() => setShowConfirmRemoveEbookModal(false)}
          onConFirm={onRemoveEbook}
          title="Remove Confirmation"
          subTitle={`Are you confirm to remove : ${pintoEbookData?.title}?`}
        />
      )}

      {/* {showConfirmHideEbookModal && (
        <ConfirmModal
          onClose={() => setShowConfirmHiedEbookModal(false)}
          onConFirm={onConfirmHideUnHideEbook}
          title={`${pintoEbookData?.status === EbookStatus.Public ? 'Unpublish' : 'Publish'} Confirmation`}
          subTitle={`Are you confirm to ${pintoEbookData?.status === EbookStatus.Public ? 'unpublish' : 'publish'} : ${pintoEbookData?.title}?`}
          confirmButtonClassname={pintoEbookData?.status === EbookStatus.Hidden ? '!btn-accent' : undefined}
        />
      )} */}

      {showSchedulePublishModal && (
        <SchedulePublishModal
          onClose={() => setSchedulePublishModal(false)}
          onConFirm={(date) => {
            setSchedulePublishModal(false);
            onSubmitPublishEbook(date);
          }}
        />
      )}
    </div>
  );
}

export default EbookDetailPage;
