import { isNull } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FiDownload, FiPlus, FiTrash2 } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import BundleSubmissionListTablePresenter from '../../app/bundles/presenters/BundleSubmissiontListTablePresenter';
import ConfirmModal from '../../app/modals/ConfirmModal';
import ConfirmInputModal from '../../app/modals/SubmitConfirmInputModal';
import Loading from '../../components/Loading';
import ToastComponent, { IToastType } from '../../components/Toast/ToastComponent';
import { useCurrentUserLazyQuery } from '../../core/graphql/operation-hooks';
import useMutationPintoDownloadEbookBundles from '../../hooks/useMutationPintoDownloadEbookBundles';
import useMutationPintoRemoveEbookBundle from '../../hooks/useMutationPintoRemoveEbookBundle';
import useQueryPintoEbookBundle from '../../hooks/useQueryPintoEbookBundle';
import useQueryPintoEbookBundleSubmissions from '../../hooks/useQueryPintoEbookBundleSubmissions';
import { downloadCSV } from '../../utils/blob';

const BundlePage = () => {
  const { ebookBundleSubmissions, fetchPintoEbookBundleSubmissions, isLoadingPintoEbookBundleSubmissions } = useQueryPintoEbookBundleSubmissions();
  const { onMutatePintoRemoveEbookBundle } = useMutationPintoRemoveEbookBundle();
  const [queryCurrentUser, { data: user }] = useCurrentUserLazyQuery();
  const { onFetchPintoEbookBundle, pintoEbookBundleData, isLoadingPintoEbookBundle } = useQueryPintoEbookBundle();
  const { downloadEbookBundleCsv, isLoadingDownloadEbookBundles } = useMutationPintoDownloadEbookBundles();
  const [showConfirmDeleteBundle, setShowConfirmDeleteBundle] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const ebookBundleDetail = useMemo(() => pintoEbookBundleData, [pintoEbookBundleData]);
  const errorMsg = useMemo(() => {
    if (hasSubmitted && !isLoadingPintoEbookBundle && !ebookBundleDetail) {
      return 'Bundle not found';
    }
    return '';
  }, [hasSubmitted, isLoadingPintoEbookBundle, ebookBundleDetail]);

  useEffect(() => {
    fetchPintoEbookBundleSubmissions();
  }, [fetchPintoEbookBundleSubmissions]);

  useEffect(() => {
    queryCurrentUser();
  }, [queryCurrentUser]);

  const handleFirstModalSubmit = useCallback(
    (inputValue) => {
      setHasSubmitted(true);
      onFetchPintoEbookBundle(inputValue);
    },
    [onFetchPintoEbookBundle],
  );

  useEffect(() => {
    if (!showConfirmDeleteBundle) {
      setHasSubmitted(false);
    }
  }, [showConfirmDeleteBundle]);

  useEffect(() => {
    if (ebookBundleDetail && !isLoadingPintoEbookBundle) {
      setShowConfirmDeleteBundle(false);
      setShowConfirmModal(true);
    }
  }, [ebookBundleDetail, isLoadingPintoEbookBundle]);

  const onSubmitDeleteBundle = useCallback(async () => {
    if (ebookBundleDetail && user?.currentUser?._id) {
      const res = await onMutatePintoRemoveEbookBundle(ebookBundleDetail._id, user?.currentUser?._id);
      const isSuccess = !!res.data?.removeEbookBundle?._id;
      if (isSuccess) {
        ToastComponent({
          label: 'Bundle deleted successfully',
          type: IToastType.SUCCESS,
        });
        setShowConfirmModal(false);
      } else {
        ToastComponent({ label: 'เกิดข้อผิดพลาดในการทำรายการ กรุณาลองใหม่อีกครั้ง', type: IToastType.ERROR });
      }
    }
  }, [ebookBundleDetail, onMutatePintoRemoveEbookBundle, user?.currentUser?._id]);

  const handleDownloadCsvClick = useCallback(async () => {
    try {
      const base64String = await downloadEbookBundleCsv();
      if (base64String) {
        await downloadCSV(base64String, 'ebook-bundles.csv');
      } else {
        ToastComponent({
          label: 'No data to download',
          type: IToastType.INFO,
        });
      }
    } catch (error) {
      ToastComponent({
        label: 'Error during the download process:',
        type: IToastType.ERROR,
      });
    }
  }, [downloadEbookBundleCsv]);

  return (
    <>
      {isLoadingDownloadEbookBundles && <Loading />}
      <div className="mx-auto space-y-24">
        {showConfirmDeleteBundle && (
          <ConfirmInputModal
            onClose={() => setShowConfirmDeleteBundle(false)}
            onSubmit={handleFirstModalSubmit}
            title="Enter Bundle ID"
            confirmText="Delete"
            cancelText="Cancel"
            subTitle="Please enter the Bundle ID you wish to delete:"
            errorMsg={errorMsg}
          />
        )}

        {showConfirmModal && (
          <ConfirmModal
            onClose={() => setShowConfirmModal(false)}
            onConFirm={onSubmitDeleteBundle}
            title="Delete Bundle"
            subTitle="Do you confirm to delete this following bundles?"
            messageList={[
              { title1: 'Bundle ID: ', title2: ebookBundleDetail?._id || 'N/A' },
              { title1: 'Bundle Title: ', title2: ebookBundleDetail?.title || 'N/A' },
              { title1: 'Username: ', title2: ebookBundleDetail?.userId || 'N/A' },
              { title1: 'Status:', title2: ebookBundleDetail?.status || 'N/A' },
            ]}
            cancelText="Cancel"
            confirmText="Confirm"
            disabledIndexOrder
          />
        )}

        <div className="space-y-24 divider-y">
          <div className="flex justify-between py-5">
            <h1 className="font-dbh text-[28px]">Bundles</h1>
            <div className="space-x-16">
              <button className="space-x-8 btn btn-error btn-outline" onClick={() => setShowConfirmDeleteBundle(true)}>
                <FiTrash2 className="font-bold text-24" />
                <span>Delete Bundle</span>
              </button>

              <Link to="/bundles/create">
                <button className="space-x-8 btn btn-error">
                  <FiPlus className="font-bold text-24" />
                  <span>Create Bundle</span>
                </button>
              </Link>
            </div>
          </div>
          <hr className="storylog-editor-hr" />
          {/* filter and search */}
          <div className="space-y-8">
            <div className="flex items-center justify-between">
              <div className="space-x-4">
                <button className="space-x-8 btn btn-error btn-outline" onClick={handleDownloadCsvClick}>
                  <FiDownload className="font-bold text-24" />
                  <span> Download Bundle list (.CSV)</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* table content */}
        <div>
          {isLoadingPintoEbookBundleSubmissions && <div className="w-full btn btn-outline btn-disabled bg-systemGrays04LabelQuaternary border-none loading h-[100px]" />}

          {!isLoadingPintoEbookBundleSubmissions && (
            <>
              {isNull(ebookBundleSubmissions) || (ebookBundleSubmissions?.length || 0) <= 0 ? (
                <div className="flex items-center justify-center w-full h-[60vh] text-colorsBrandWarmBlack02LabelSecondary">No list of ebook bundle submission</div>
              ) : (
                <BundleSubmissionListTablePresenter submissions={ebookBundleSubmissions} />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default BundlePage;
