import { onError } from '@apollo/client/link/error';
import find from 'lodash/find';

import { deleteCookie } from '../../utils/cookie';
import { setItemLocalStorage } from '../../utils/localStorage';
import { cookieName } from '../cookieName';
import { localStorageItemsKeyName } from '../localStorageItemsKeyName';

const operationNamesToHideErrorModal = ['CreateBookPageAd', 'PintoRemoveEbookBundle', 'CreatePintoSubscriptionTitle', 'EditPintoSubscriptionTitle'];
// for display reserved tags in error
// MutationEditDraftBook, MutationEditBook
const statusCodeToHideErrorModal = [404, 401];

export type FictionlogGraphQLError = {
  statusCode: number; // 404
  error: string; // "Not Found"
  message: string; // "ไม่พบใบกำกับภาษีนี้"
  originalMessage: string; // "tax_not_found"
};

const CREATE_DISCOUNT_CAMPAIGN_ERROR_MESSAGE = 'create_ebook_discount_campaign.invalid_input';
const CREATE_EBOOK_BUNDLE_ERROR_MESSAGE = 'create_ebook_bundle.invalid_input';

const errorLink = onError((error) => {
  const { graphQLErrors = [], operation, forward } = error;

  const fictionlogGraphQLError = graphQLErrors[0] as unknown as FictionlogGraphQLError;
  const statusCode = fictionlogGraphQLError?.statusCode;

  if (find(graphQLErrors, { statusCode: 401 })) {
    deleteCookie(cookieName.ACCESS_TOKEN);
    alert(`เกิดข้อผิดพลาด ${graphQLErrors.join(', ')}`);
    forward(operation);
    window.location.reload();
    return;
  }

  const isErrorBundleListing = graphQLErrors.find(({ message }) => message === CREATE_EBOOK_BUNDLE_ERROR_MESSAGE);
  if (isErrorBundleListing) {
    setItemLocalStorage(localStorageItemsKeyName.UPLOAD_BUNDLE_ERROR_ITEMS, JSON.stringify(graphQLErrors[0]));
    forward(operation);
    return;
  }

  const isIgnoredOperationName = operationNamesToHideErrorModal.includes(operation.operationName);
  const isIgnoredStatusCode = statusCodeToHideErrorModal.includes(statusCode);
  if (isIgnoredOperationName || isIgnoredStatusCode) {
    forward(operation);
    return;
  }

  //HANDLE ERROR ONLY CASE DISCOUNT LISTING
  const isErrorDiscountListing = graphQLErrors.find(({ message }) => message === CREATE_DISCOUNT_CAMPAIGN_ERROR_MESSAGE);
  if (isErrorDiscountListing) {
    setItemLocalStorage(localStorageItemsKeyName.DISCOUNT_CAMPAIGN_ERROR_ITEMS, JSON.stringify(graphQLErrors[0]));
  } else {
    alert(graphQLErrors.map(({ message }) => message).join(', '));
  }
  forward(operation);
});

export default errorLink;
